import * as React from "react";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((post) => (
      <article className="tile box" key={post.node.fields.slug}>
        <header>
          <p className="post-meta">
            <Link to={post.node.fields.slug}>
              <h2 className="is-size-4">{post.node.frontmatter.title}</h2>
            </Link>
            {post.node.excerpt}
          </p>
        </header>
      </article>
    ));
    const tag = this.props.pageContext.tag;
    const title = this.props.data.site.siteMetadata.title;
    const totalCount = this.props.data.allMarkdownRemark.totalCount;
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? "" : "s"
    } tagged with “${tag}”`;

    return (
      <Layout>
        <section className="section">
          <Helmet title={`${tag} | ${title}`} />
          <h3 className="title has-text-centered is-size-4 is-bold-light">{tagHeader}</h3>
            <div className="columns is-multiline">
              <div className="is-parent column is-12">
                {postLinks}
              </div>
            </div>
            <div className="has-text-centered">
              <Link to="/tags/">
                <span className="button title button is-card-cta is-size-5-tablet is-size-6-touch">
                  Browse all tags
                </span>
              </Link>
            </div>
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
